.mat-dialog-container {
  padding: 0 !important;
  .mat-dialog-content {
    margin: 0;
    padding: .8em 2em 0em 2em;
  }
  .mat-dialog-actions {
    margin: 0;
    padding: .5em 2em;
    min-height: auto;
  }

}
.modal-header {
  background: map-get($theme-colors, "dark-blue");
  padding: 1.5em 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .title-box {
    display: flex;
    align-items: center;
    i {
      font-size: 2em;
      display: inline-block;
      transform: rotate(135deg);
      margin-right: 0.4em;
      color: map-get($theme-colors, "yellow");
    }
    h3 {
      margin: 0;
      color: lighten(map-get($theme-colors, "dark-blue"), 50%);
      font-weight: 400;
      font-size: 1.5em;
    }
  }
  .close-btn {
    button {
      color: lighten(map-get($theme-colors, "dark-blue"), 10%);
      border: 0;
      padding: 0.7em 0.6em;
      border-radius: 10em;
      cursor: pointer;
      display: inline-flex;
      align-items: center;
      background: transparent;
      transition: $btn-transitions;
      &:hover {
        background: darken(map-get($theme-colors, "dark-blue"), 10%);
      }
    }
  }
}
.modal-body {
  fieldset {
    display: flex;
    flex-flow: column nowrap;
    border: none;
    padding: 0;
    margin: 0;
    &:not(:first-child) {
      margin-top: 1rem;
    }
    .section-title {
      padding: 1em 0em;
      label {
        font-size: 1.1em;
        font-weight: 600;
        color: darken(map-get($theme-colors, "gray"), 20%);
      }
      .highlight-bar {
        width: 56px;
        height: 3px;
        margin: 5px 0 10px 0;
        background-color: map-get($theme-colors, "yellow");
      }
    }
    .row {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
      column-gap: 1rem;
    }
  }
  .mat-dialog-actions {
    padding: 0.5em 2em 1.5em 2em;
    app-action-button {
      display: flex;
    }

    .mat-flat-button.mat-primary:not(.mat-button-disabled) {
      background-color: map-get($theme-colors, "yellow");
    }
  }
}
.mat-flat-button.mat-primary:not(.mat-button-disabled) {
  background-color: map-get($theme-colors, "yellow");
}
.section-title {
  padding: 1em 0em;
  label {
    font-size: 1.1em;
    font-weight: 600;
    color: darken(map-get($theme-colors, "gray"), 20%);
  }
  .highlight-bar {
    width: 56px;
    height: 3px;
    margin: 5px 0 10px 0;
    background-color: map-get($theme-colors, "yellow");
  }
}

@media only screen and (max-width: 767px) {
  .cdk-overlay-pane.app-new-appointment-dialog-panel,
  .cdk-overlay-pane.app-dealership-vehicle-pickup-dialog-panel,
  .cdk-overlay-pane.app-location-dialog-panel,
  .cdk-overlay-pane.app-employee-invite-panel,
  .cdk-overlay-pane.app-select-assigned-tenants-panel {
    max-width: 95vw !important;
    width: 95% !important;
  }
}
