@import '../abstracts/colors';


body {
  font-family: nudista-web, sans-serif;
}
h1 {
  font-size: 3.375em;
  color: map-get($theme-colors, "black");
  line-height: 1.3em;
}
h2 {
  font-size: 2.188em;
  color: map-get($theme-colors, "black");
}
h3 {
  font-size: 1.250em;
  color: map-get($theme-colors, "black");
}
h6 {
  font-size: 1.188em;
  font-weight: 500;
  color: map-get($theme-colors, "gray");
  line-height: 1.8;
}
p {
  font-size: 1.000em;
  color: map-get($theme-colors, "gray");
  line-height: 1.8;
  font-weight: 400;
}
a {
  color: map-get($theme-colors, "yellow");
  &:hover {
    color:lighten(map-get($theme-colors, "yellow"), 5%)
  }
}
