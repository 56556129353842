button {
  &:focus {
    outline:0;
  }
}
button.square {
  border: 0;
  padding: .7em 1.5em;
  border-radius: 0.5em;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  line-height: 1em;
  transition: $btn-transitions;
  a {
    letter-spacing: 0.05em;
    font-weight: 600;
    text-decoration: none;
  }
  i {
    font-size: .5em;
  }
  &.xl {
    font-size: 1.3em;
    padding: .8em 2em;
    font-weight: 500;
  }
  &.sm {
    padding: 0.5em 0.8em;
  }
  mat-icon {
    margin-right: .2em;
    height: 1.2em;
  }
  &.yellow {
    background: map-get($theme-colors, "yellow");
    a {
      color: #fff;
      &.text-shadow {
        text-shadow: 1px 1px 1px #0000005e;
      }
    }
    i {
      color: #fff;
    }
    &:hover {
      background:lighten(map-get($theme-colors, "yellow"), 5%)
    }
  }
  &.gray {
    background: map-get($theme-colors, "action-gray");
    a {
      color: map-get($theme-colors, "gray");
      &.text-shadow {
        text-shadow: 1px 1px 1px #0000005e;
      }
    }
    i {
      color: map-get($theme-colors, "gray");
    }
    &:hover {
      background:lighten(map-get($theme-colors, "action-gray"), 2%)
    }
  }
  &.actions {
    background: #ffffff;
    box-shadow: inset 0px 0px 0px 1px #dae1ef;
    a {
      color: map-get($theme-colors, "yellow");
      &.text-shadow {
        text-shadow: 1px 1px 1px #0000005e;
      }
    }
    i {
      color: map-get($theme-colors, "yellow");
    }
    &:hover {
      background:lighten(map-get($theme-colors, "action-gray"), 2%)
    }
  }
  &.turquoise {
    background: map-get($theme-colors, "turquoise");
    color: map-get($theme-colors, "white");
    a {
      color: map-get($theme-colors, "yellow");
      &.text-shadow {
        text-shadow: 1px 1px 1px #0000005e;
      }
    }
    i {
      color: map-get($theme-colors, "white");
    }
    &:hover {
      background:lighten(map-get($theme-colors, "turquoise"), 2%)
    }
  }
}
