@font-face {
  font-family: 'doorcaptain';
  src: url('../font/doorcaptain.eot?26494038');
  src: url('../font/doorcaptain.eot?26494038#iefix') format('embedded-opentype'),
       url('../font/doorcaptain.woff2?26494038') format('woff2'),
       url('../font/doorcaptain.woff?26494038') format('woff'),
       url('../font/doorcaptain.ttf?26494038') format('truetype'),
       url('../font/doorcaptain.svg?26494038#doorcaptain') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'doorcaptain';
    src: url('../font/doorcaptain.svg?26494038#doorcaptain') format('svg');
  }
}
*/
 
 [class^="icons-"]:before, [class*=" icons-"]:before {
  font-family: "doorcaptain";
  font-style: normal;
  font-weight: normal;
  speak: never;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.icons-break:before { content: '\e800'; } /* '' */
.icons-collapse:before { content: '\e801'; } /* '' */
.icons-hat:before { content: '\e802'; } /* '' */
.icons-more-info:before { content: '\e803'; } /* '' */
.icons-offduty:before { content: '\e804'; } /* '' */
.icons-onflight:before { content: '\e805'; } /* '' */
.icons-pin:before { content: '\e806'; } /* '' */
.icons-search:before { content: '\e807'; } /* '' */
.icons-stationary:before { content: '\e808'; } /* '' */
.icons-wings:before { content: '\e809'; } /* '' */
.icons-settings:before { content: '\e80a'; } /* '' */
.icons-resize-full:before { content: '\e80b'; } /* '' */
.icons-resize-small:before { content: '\e80c'; } /* '' */
.icons-phone:before { content: '\e80d'; } /* '' */
.icons-help-circled:before { content: '\e80e'; } /* '' */
.icons-info-circled:before { content: '\e80f'; } /* '' */
.icons-ok-circle:before { content: '\e810'; } /* '' */
.icons-cancel-circle:before { content: '\e811'; } /* '' */
.icons-minus-circle:before { content: '\e812'; } /* '' */
.icons-attention:before { content: '\e814'; } /* '' */
.icons-ok:before { content: '\e815'; } /* '' */
.icons-cancel:before { content: '\e816'; } /* '' */
.icons-calendar:before { content: '\e818'; } /* '' */
.icons-camera:before { content: '\e819'; } /* '' */
.icons-car:before { content: '\e81a'; } /* '' */
.icons-finder:before { content: '\e81b'; } /* '' */
.icons-insurance:before { content: '\e81c'; } /* '' */
.icons-key:before { content: '\e81d'; } /* '' */
.icons-license:before { content: '\e81e'; } /* '' */
.icons-phone-1:before { content: '\e81f'; } /* '' */
.icons-pin2:before { content: '\e820'; } /* '' */
.icons-timer:before { content: '\e821'; } /* '' */
.icons-writer:before { content: '\e822'; } /* '' */
.icons-xls:before { content: '\e823'; } /* '' */
.icons-arrow-down:before { content: '\e824'; } /* '' */
.icons-config:before { content: '\e825'; } /* '' */
.icons-points:before { content: '\e826'; } /* '' */
.icons-chevron-down:before { content: '\e827'; } /* '' */
.icons-logout-icon:before { content: '\e828'; } /* '' */
.icons-new-task:before { content: '\e829'; } /* '' */
.icons-timer-off:before { content: '\e82b'; } /* '' */
.icons-spin5:before { content: '\e838'; } /* '' */
.icons-spin6:before { content: '\e839'; } /* '' */