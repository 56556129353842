// @use './fonts/roboto';
// @use './fonts/material';
@use './globals';

// Module imports

@use './app/core/scss/dc-theming.scss';

@use '~@angular/material' as mat;
@import "~ng-snotify/styles/material";
@import './app/core/scss/components/snotify';
@import url("https://use.typekit.net/ikk0pmz.css");

@import './app/core/forms/telephone/telephone';
@import './app/core/forms/date-time-picker/date-time-picker';

/* Valet Custom Font */
@import './assets/css/doorcaptain.css';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Include the default theme styles.
@include mat.all-component-themes(globals.$light-theme);

snack-bar-container.error {
  background-color: #bd362f;
  opacity: .8;
  color: #FFF;
}

snack-bar-container.success {
  background-color: #FFF;
  color: black;
  opacity: .8;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 9999;
}
