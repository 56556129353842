
.mat-select {
  font-family: nudista-web, sans-serif;
}
.mat-option {
  font-family: nudista-web, sans-serif;
}
.mat-expansion-panel-header {
  font-family: nudista-web, sans-serif;
}
.mat-table {
  font-family: nudista-web, sans-serif;
}
.mat-list-item {
  font-family: nudista-web, sans-serif;
}
.mat-form-field {
  font-family: nudista-web, sans-serif;
}
