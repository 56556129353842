form {
  .section-title {
    padding: 1em 0em;
    label {
      font-size: 1.1em;
      font-weight: 600;
      color: darken(map-get($theme-colors, "gray"), 20%);
    }
    .highlight-bar {
      width: 56px;
      height: 3px;
      margin: 5px 0 10px 0;
      background-color: map-get($theme-colors, "yellow");
    }
  }
}

// form styles

mat-form-field.mat-form-field-appearance-fill {
  .mat-form-field-wrapper {
    .mat-form-field-flex {
      border-radius: 0.5em;
      padding: 0.25em 0.75em 0.25em 0.75em;
      background-color: lighten(map-get($theme-colors, "light-gray"), 15%);
      .mat-form-field-infix {
        padding: 0.25em 0 0.25em 0;
        .mat-form-field-label-wrapper {
          top: -0.7em;
          left: 0em;
        }
      }
    }
    .mat-form-field-underline {
      display: none;
    }
  }
}

mat-form-field.mat-focused.mat-form-field-appearance-fill {
  .mat-form-field-wrapper {
    .mat-form-field-flex {
      box-shadow: 0px 0px 12px #9e9e9e52;
      .mat-form-field-infix {
        .mat-form-field-label-wrapper {
          .mat-form-field-label {
            color: map-get($theme-colors, "yellow");
          }
        }
      }
    }
    .mat-form-field-underline {
      .mat-form-field-ripple {
        background-color: map-get($theme-colors, "yellow");
      }
    }
  }
}

mat-form-field.mat-form-field-invalid.mat-form-field-appearance-fill {
  .mat-form-field-wrapper {
    .mat-form-field-flex {
      background-color: lighten(map-get($theme-colors, "danger"), 30%);
      .mat-form-field-infix {
        .mat-form-field-label-wrapper {
          .mat-form-field-label {
            color: map-get($theme-colors, "danger");
          }
        }
      }
    }
    .mat-form-field-underline {
      .mat-form-field-ripple {
        background-color: map-get($theme-colors, "danger");
      }
    }
  }
}

