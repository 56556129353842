.ml-05 {
  margin-left: .5em;
}
.mr-05 {
  margin-left: .5em;
}
.ml-1 {
  margin-left: 1em;
}
.ml-2 {
  margin-left: 2em;
}
.mr-1 {
  margin-right: 1em;
}
.mr-2 {
  margin-right: 2em;
}
.mt-1 {
  margin-top:1em;
}
.mb-1 {
  margin-bottom:1em;
}
.m-0 {
  margin:0;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
.d-inline-flex {
  display: inline-flex;
}
.d-flex {
  display: flex;
}
