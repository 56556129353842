.snotify {
  display: block;
  position: fixed;
  width: 500px;
  z-index: 9999;
  box-sizing: border-box;
  pointer-events: none;
  * {
    box-sizing: border-box;
  }
}

.snotifyToast {
  display: block;
  cursor: pointer;
  background-color: #fffbee;
  max-height: 300px;
  min-height: 150px;
  height: 100%;
  margin: 5px;
  margin-bottom: 2em;
  opacity: 0;
  overflow: hidden;
  pointer-events: auto;
  &:last-child {
    box-shadow: 1px 0px 13px 0px #dcdcdc, -1px -2px 17px 20px #ffffff, -20px 0px 60px 50px #ffffff, -20px 0px 20px 20px #ffffff;
  }
  &:first-child {
    box-shadow: 1px 0px 13px 0px #dcdcdc, -1px -2px 17px 20px #ffffff, 0px -16px 60px 20px #ffffff3d, -20px 0px 20px 20px #ffffff;
  }
  .snotifyToast__inner {
    padding: 5px 15px 5px 15px;
  }
  &.on-the-way {
    background: #fffbee;
    border: 1px solid #e9b839;
    border-radius: 1em;
    .snotifyToast__header {
      display: flex;
      justify-content: space-between;
      font-size: 1.3em;
      font-weight: bold;
      padding-bottom: .5em;
      padding-top: .5em;
      border-bottom: 1px solid #FFA400;
      width: 100%;
      i {
        color: #e9b839;
        margin-right: .5em;
      }
    }
    .snotifyToast__body {
      padding: 20px 0px;
      display: flex;
      .toast-txt {
        line-height: 1.6;
      }
    }
  }
  &.on-site {
    background: #ebfaf3;
    border: 1px solid #2cc567;
    border-radius: 1em;
    .snotifyToast__header {
      display: flex;
      justify-content: space-between;
      font-size: 1.3em;
      font-weight: bold;
      padding-bottom: .5em;
      padding-top: .5em;
      border-bottom: 1px solid #2CC567;
      width: 100%;
      i {
        color: #2cc567;
        margin-right: .5em;
      }
    }
    .snotifyToast__body {
      padding: 20px 0px;
      display: flex;
      .toast-txt {
        line-height: 1.6;
      }
    }
  }
  &.new-appointment {
    background: #ffffff;
    border: 1px solid #d4d4d4;
    border-radius: 1em;
    .snotifyToast__header {
      display: flex;
      justify-content: space-between;
      font-size: 1.3em;
      font-weight: bold;
      padding-bottom: .5em;
      padding-top: .5em;
      border-bottom: 1px solid #777777;
      width: 100%;
      i {
        color: #777777;
        margin-right: .5em;
      }
    }
    .snotifyToast__body {
      padding: 20px 0px;
      display: flex;
      .toast-txt {
        line-height: 1.6;
      }
    }
  }
}

.snotify-leftTop,
.snotify-leftCenter,
.snotify-leftBottom {
  left: 10px;
}

@media only screen and (max-width: 767px) {
  .snotify {
    width: 95%;
  }
  .snotifyToast {
    margin-bottom: .5em;
    &:last-child {
      box-shadow: unset;
    }
    &:first-child {
      box-shadow: unset;
    }

  }
}
